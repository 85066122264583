.listing {
  &-slider {
    color: $white;
    position: relative;
    max-height: 290px;
    overflow: hidden;

    &__item {
      position: relative;
      height: 290px !important;

      img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
      }

      .wrapper-inner {
        margin-right: 67px;
      }
    }


    &-nav {
      right: 46px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      height: 168px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      .info{
        text-align: center;
      }

      .listing-slider-prev,
      .listing-slider-next {
        cursor: pointer;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: $yellow;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    &__text {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #414D5F;

    }
  }
}

.display-btns {
  display: flex;
  align-items: center;
}

.display-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  position: relative;
  overflow: hidden;

  svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;

    rect {
      fill: #777777;
    }
  }


  &:hover, &.active {
    border-color: transparent;

    svg rect {
      fill: $yellow;
    }

    &::after {
      opacity: 1;
    }
  }
}