.header {
  background: $black;
  box-shadow: 0px 6px 14px rgba(31, 31, 31, 0.06);
  color: $white;
  padding: 11px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    min-width: 124px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0;
    padding: 0;

    &-item {
      margin-right: 17px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      transition: 0.3s;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;

      &:hover {
        color: $yellow;
      }
    }
  }

  &__search {
     margin-right: 14px;
    // margin-left: 20px;
    flex: 1;

    &-form {
      width: 100%;
      position: relative;
    }

    &-input {
      width: 100%;
      background: #F5F5FA;
      border: 1px solid #E3EAF7;
      border-radius: 20px;
      padding: 0 50px 0 20px;
      height: 40px;
      line-height: 38px;
      font-weight: 600;
      font-size: 13px;
      font-family: $font-meri;
      color: $yellow;
      transition: 0.3s;

      &::placeholder {
        color: $grey;
      }

      &:hover {
        border: 1px solid $yellow;
      }

      &:focus {
        outline: none;
        border: 1px solid $yellow;
      }
    }

    &-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      border: none;
      background-color: transparent;
      stroke: $grey;
      transition: 0.3s;

      &:hover {
        stroke: $yellow;
      }

      &:focus {
        outline: none;
      }
    }
    .header__search-bckg {
      opacity: 0;
      visibility: hidden;
    }
    &.mobile {
      position: absolute;
      // top: -10%;
      top: 100%;
      left: 0;
      width: 100%;
      transition: all .3s ease;
      visibility: hidden;
      opacity: 0;

      .header__search-input {
        max-width: 100% !important;
        border-radius: 0 !important;
      }

      &.active {
        opacity: 1;
        visibility: visible;
          // top: 100%;
        .header__search-bckg {
          display: block;
          background: #111111;
          opacity: 0.8;
          width: 100%;
          visibility: visible;
          height: 100vh;
        }
      }

      .makeStyles-root-49 {
        max-width: 100%;
        border-radius: 0;
      }

      
    }
  }

  &__group {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      position: relative;

      &:nth-last-child(1) {
        padding-right: 0;

      }

      &:nth-child(1) {
        padding-left: 0;
        cursor: pointer;

        &::before {
          content: unset;
        }
      }

      &::before {
        content: '';
        position: absolute;
        background: rgba($white, 0.08);
        left: 0;
        top: 50%;
        width: 1px;
        height: 26px;
        transform: translateY(-50%);
      }

      & .favourite__icon-count{
        position: absolute;
        background: #FFBE00;
        border-radius: 50px;
        width: 18px;
        height: 18px;
        top: 0;
        text-align: center;
        color: #1F1F1F;
        font-size: 12px;
        font-weight: 600;
        line-height: 150%;
        transform: translate(135%, -15%);
      }

      &__langs {
        margin-top: 5px;
        margin-left: auto;
      }

      svg {
        transition: 0.3s;
        margin-right: 0;

        &:hover {
          &.svg-fill {
            rect,
            path {
              fill: $yellow;
            }
          }

          &.svg-stroke {
            rect,
            path {
              stroke: $yellow;
            }
          }
        }
      }

      .btn-icon {
        &:hover {
          color: $yellow;

          svg.svg-fill {
            rect,
            path {
              fill: $yellow;
            }
          }

          svg.svg-stroke {
            rect,
            path {
              stroke: $yellow;
            }
          }
        }
        &.active {
          color: $yellow;

          svg.svg-fill {
            rect,
            path {
              fill: $yellow;
            }
          }

          svg.svg-stroke {
            rect,
            path {
              stroke: $yellow;
            }
          }
        }
      }
    }

    .btn {
      flex-shrink: 0;
      text-align: center;
    }

  }

  &-user {
    &__group {
      &.on-popup {
        .btn-icon {
          margin: 0 9px;
  
          svg path{
            stroke: #000;
          }
  
          &:nth-child(1) {
            margin-left: 0;
          }
        }
      }
      .btn-icon {
        margin: 0 9px;

        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }

    &__info {
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      position: relative;
      cursor: pointer;

      &-header {
        display: flex;
        align-items: center;

        svg {
          &:nth-child(1) {
            margin-right: 7px;
          }

          &:nth-last-child(1) {
            margin-left: 7px;
          }
        }
      }

      &-body {
        padding: 15px 20px;
        box-sizing: border-box;
      }
    }
  }

  &-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // width: 100%;

    .header__search-mobile {
      display: none;
    }
  }

  &-show__mobile {
    display: none;

    .header__search {
      .close {
        display: none;
      }

      .open {
        display: block;
      }

      &.active {
        .open {
          display: none;
        }

        .close {
          display: block;
        }
      }
    }
  }

  &-logo__block {
    display: flex;
    align-items: center;
  }

  &-price {
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    color: $white;
    flex-direction: column;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    z-index: 8;
    padding-right: 20px;
    padding-left: 10px;
    text-align: center;

    @media screen and(max-width: 520px) {
      font-size: 11px;
      padding-right: 10px;
    }

    span {
      font-weight: 500;
      display: block;
      color: rgba($white, 0.6);
      font-size: 12px;
      line-height: 100%;
      margin-top: 6px;
      transition: 0.3s;

      @media screen and(max-width: 520px) {
        margin-top: 0;
      }
    }

    &:hover {
      color: $yellow;

      span {
        color: rgba($yellow, 0.6);
      }
    }
  }
}

.header__menu-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 40px;
  height: 40px;
  display: none;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: #1F1F1F;
    }
  }

  &1 {
    width: 30px;
    height: 30px;
    margin-left: 5px;

    svg path {
      stroke: $white;
    }

    svg rect {
      fill: $white;
    }

    .line {
      fill: none;
      stroke: $white;
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }

    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }

    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }

    .active {
      .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }

      .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }

      .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
    }

  }

  &:focus {
    outline: none;
  }
}

.btn-home {
  margin: 0 20px;
  min-width: 184px;

  .header__menu-btn {
    width: 22px;
    height: 12px;
    margin-right: 8px;
    display: block;
  }
}


.header, .header-mobile {
  input[type="radio"] {
    cursor: none;
    visibility: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;

    &:nth-of-type(1):checked ~ .b_t {
      left: 0;

      &::before {
        background: $yellow;
      }
    }

    &:nth-of-type(2):checked ~ .b_t {
      left: calc(50% - 10px);
    }

    &:nth-of-type(3):checked ~ .b_t {
      left: calc(100% - 20px);

      &::before {
        background: $yellow;
      }
    }
  }

  .toggle {
    cursor: pointer;
    display: flex;
    width: 12px;
    height: 16px;
    position: relative;
  }

  .toggle_button {
    cursor: none;
    caret-color: transparent;
    width: min-content;
    display: flex;
    flex-direction: row;
    justify-items: center;
    background-color: #4F4F4F;
    border-radius: 50px;
    position: relative;
    margin: 0 10px;
  }

  .b_t {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    transition: left 0.3s cubic-bezier(0.87, -0.32, 0.48, 1.35);
    //left: 0;
    top: -1px;
    left: calc(50% - 10px);

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      margin: 10%;
      display: block;
      padding: 0;
      border-radius: 50%;
      background: $white;
      box-shadow: 0px 5px 8px rgba(255, 255, 255, 0.3);
      transition: 0.3s;
    }
  }
}

.header-mobile {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding-top: 0;
  z-index: 5;
  color: $black;
  top: 0;
  left: -100vw;
  transition: 0.3s;
  max-width: 60%;

  .close-icon {
    position: absolute;
    right: 0;
    top: 15px;
    cursor: pointer;
  }

  .MuiDialog-scrollPaper {
    padding: 0 20px 0 0;
    position: relative;

    .MuiDialog-paper{
      padding: 0 30px 0 0;
      box-shadow: none !important;
      background-color: transparent;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px 0;
  }

  &__inner {
    background: $white;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px 0;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      width: 100%;
      background-color: $yellow;
    }

    .line {
      width: 100%;
      height: 1px;
      border-top: 1px solid rgba(#414D5F, 0.12);
    }

    .langs {
      display: flex;
      align-items: center;


      .langs__item {
        margin-right: 10px;
        color: rgba(65, 77, 95, 0.5);

        &.active {
          color: #FFBE00;
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }

  &__like {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #1F1F1F;

    svg {
      margin-right: 8px;

      path {
        stroke: $black;
      }
    }


  }

  &.active {
    left: 0;
  }


  &__search {
    display: block;
    top: 0;
    height: 50px;
    position: fixed;
    width: 100%;
    z-index: 0;
    transition: 0.3s;
    left: 0;

    &.active {
      top: 66px;
      z-index: 3;
    }

    .header__search-input {
      height: 50px;
      border-radius: 0;
    }
  }

}

@media screen and (max-width: 1850px) {
  .btn-header__select {
    font-size: 0;
  }
}

@media screen and (max-width: 1700px) {
  .header__nav {
    // display: none;
    padding-left:20px;
  }
  .header__menu-btn {
    display: flex;
  }
  .header-mobile {
    display: block;

    .header__nav {
      display: flex;
      flex-wrap: wrap;
    }

    .header__nav-item {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header-desktop {
    .header__search {
      display: block;
    }

    .header__search-mobile {
      flex: 0;
      display: flex;
      .open{
        display: block;
      }
      .close{
        display: none;
      }
      &.active{
        .close{
          display: block;
        }
        .open{
          display: none;
        }
      }
    }
    .btn{
      font-size: 0;
    }
  }
}

.header__group-item {
  padding: 6px ;
}

@media screen and (max-width: 1700px){
  .btn-home{
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .MuiButton-text.cartBtn {
    padding: 6px 18px 6px 7px !important;
    min-width: auto !important;
  }
  .header__group-item {
    padding: 4px;

    .btn {
      font-size: 0;
    }
  }
  .svg-group span {
    right: -9px;
  }
  .btn-home {
    display: none;
  }
  .header {
    .header__menu-btn1 {
      display: flex;
      margin-right: 8px;
    }

    // &-desktop {
    //   display: none;
    // }

    &__search {
      margin-right: 0;
    }

    &-show__mobile {
      display: flex;
      align-items: center;
    }

    &__group-item {
      // padding: 0 10px;

      &::before {
        content: unset;
      }
    }
  }
}
@media screen and (max-width: 950px) {
  .header {
    &__nav {
      display: none;
    }
  }
}
@media screen and (max-width: 670px) {
  
  .header__group-item {
    padding: 0 2px;


    .MuiButton-root {
      min-width: 0px;
    }
  }
  .header-mobile {
    max-width: 100%;
  }
  .header {
    .header__menu-btn1 {
      width: 26px;
    }
  }
}

@media screen and (max-width: 550px) {
  .header {

    &__logo {
      max-width: 64px;
      min-width: 64px;
    }
  }

  .header-mobile__search.active {
    top: 50px;
  }
}

// @include media-breakpoint-down(lg) {
//   .header {
//     &__nav {
//     }
//   }
// }

.header-user__info-body {
  top: 0;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  right: 45px;
  padding: 0;
  color: $black;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  max-width: 320px;
  box-sizing: border-box;
  border-radius: 4px;
  // overflow: hidden;
  margin: 70px 0 0;
  background-color: #F5F7FB;
  box-shadow: 0px 0px 50px -15px rgba(33,33,33,1);

  @media screen and(max-width: 1500px) {
    right: 25px;
  }
  @media screen and(max-width: 1200px) {
    right: 15px;
  }

  &.profile-page {
    position: static;
    margin: 0;
    opacity: 1;
    visibility: visible;
    max-width: unset;
    box-shadow: none;
  & .header-user__info-body__body {
    @media screen and(max-width: 430px) {
      padding: 15px 0px;
    }
  }

    @media screen and(max-width: 490px) {
      font-size: 10px;
      font-weight: 700; 
    }
  }

  &__inner {
    padding: 10px;
    // overflow: hidden;
  }

  &__head {
    padding: 15px 20px;
    background: #F5F7FB;

    ul {
      padding: 0;

      li {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }


  .line {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: rgba(#9399A2, 0.2);
      left: 0;
      top: 0;
    }
  }

  .info-list {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: #777777;
    display: flex;

    li {
      margin-bottom: 0;
    }

    li:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__body {
    padding: 15px 20px;
    background: $white;

    ul {
      padding: 0;

      li {
        display: flex;

        margin-bottom: 17px;

        li {
          margin-bottom: 10px;
        }

        .icon {
          margin-right: 8px;
        }

        .title {
          font-size: 13px;
          line-height: 100%;
          color: #414D5F;
          margin-bottom: 7px;
        }

        &.active {
          .title {
            color: $blue;
          }

          .info-list {
            color: $black;
          }
        }
      }
    }

    .logout {
      font-size: 13px;
      line-height: 100%;
      color: #414D5F;
      display: flex;
      align-items: center;
      padding-top: 5px;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.header-user__info {
  // &:hover,
  &.active {
    .header-user__info-body {
      opacity: 1;
      visibility: visible;
      min-width: 320px;
    }
  }
  &.profile-page {
    background: #FFFFFF;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    margin-top: 10px;
    padding: 15px 0;
    text-align: center;
    .info-list {
      color: #777777;
    }
    & .header-user__info-header{
      justify-content: center;
      margin-top: 10px;
      padding-bottom: 10px;
    }
      .header-user__info-body__inner {
        padding: 10px 0;
    }
  }
}
#header .header-user__info-header, #header .header-user__info {
  padding: 0 !important;
}
.header-user__info-header {
  .head {
    margin-bottom: 6px;
    text-align: center;
    width: 100%;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.wrapper-profile{
  .head{
    white-space: wrap;
  }
}

.info-list {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: rgba($white, 0.6);
  display: flex;
  padding: 0;

  li {
    margin-bottom: 0;
  }

  li:not(:last-child) {
    margin-right: 10px;
  }
}
#header .header-user__info-body__inner a > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12, #header .promLink{
  display: none !important;
}
#header .header-user__info-body__body{
  justify-content: left !important;
}
#header .header-user__info-body__inner  a > div > div{
  margin: 0 0 10px !important;
}
#header .header-user__info-header > span > div > span{
  color : #FFFFFF !important;
justify-content: left !important;
}