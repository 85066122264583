.product {
  &__list,
  &__list-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 120px;
    margin-bottom: -120px;
  }

  &__list {
    //display: flex;
    //flex-wrap: wrap;
    //margin: 0 -10px;

    .slick-list {
      margin: 0 -10px;

      .product__item {
        max-width: 100%;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      margin: 0 10px;
      height: auto;
    }

    &-wrapper {
      // max-width: calc(100% - 308px);
      width: 100%;

    }
  }

  &__logo {
    margin-bottom: 20px;
  }

  .swiper {
    overflow: unset;
  }

  &__item.hr {
    max-width: calc(33% - 20px);
    justify-content: space-between;
    min-height: 200px;
    padding: 10px;
    .product__top {
      display: flex;
      flex-direction: row;
    }
    .product__inner {
      padding: 5px 10px;
      width: 100%;
    }
    .product__img {
      flex: auto;
      min-height: auto;
      padding-top: 0;
    }
    .product-end{
      .line {
        border-top: 1px solid #EFEFEF;
        margin: 12px 0;
      }
    }
    .product-footer{
      position: relative;
      .like {
        top: 50%;
        right: calc(10% + 35px);
        transform:translateY(-50%)
      }
    }
  }

  &__item {
    // margin: 0 0 20px;
    border-radius: 4px;
    background-color: $white;
    max-width: calc(20% - 20px);
    width: 100%;
    min-width: 160px;
    // cursor: pointer;
    box-shadow: 0px 18px 40px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;

    .timers {
      display: flex;
      justify-content: center;
      gap: 1px;
      .timer {
        background: #EBF0F8;
        border-radius: 45px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-weight: 400;
        }
      }
    }

    .product__img {
      cursor: pointer;
      flex: 1;
      max-height: 215px;

      // @media screen and (max-width: 450px) {
      //   min-height: auto;
      //   padding-top: 15px;
      //   max-height: 120px;
      // }

      img {
        width: auto;
        max-height: 215px;
        // max-width: 100%;
      }
    }

    &.swiper-slide {
      max-width: 100%;
    }
    &.empty {
      opacity: 0.36;
    }

    .product-top {
      background: #fff;
      z-index: 6;
      // position: relative;
    }

    .product-end {
      // position: absolute;
      width: 100%;
      // padding: 0 20px;
      background: #fff;
      z-index: 2;
      transition: 0.3s;
    }

    

    .single-product-list {
      font-size: 13px;
      line-height: 140%;
      color: #414D5F;

      li {
        margin-bottom: 5px;
      }
      
      .disabled {
       color: #BBBBBB,
      }

      .count {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
      }
    }
    &-info {
      background: #EBF0F8;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
      min-width: 288px;
      max-width: 288px;
      width: 100%;
      margin: 0;
      height: 450px;

      @media screen and (max-width: 1440px) {
        min-width: 262px;
      }

      @media screen and (max-width: 1200px) {
        min-width: 222px;
      }

      @media screen and (max-width: 1023px) {
        min-width: 262px;
      }

      &__inner {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;


        .btn {
          height: 56px;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
        }

        .full {
          flex: 1;
        }
      }

      .description {
        margin-bottom: 10px;
      }

    }

    &-category {
      padding: 25px 20px;
      display: flex !important;
      flex-direction: column;

      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        margin-bottom: 10px;

        span {
          color: #999999;
        }
      }

      &__img {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          position: absolute;
          width: 175px;
          height: 175px;
          border-radius: 50%;
          background-color: $yellow;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
        }

        img {
          position: relative;
          z-index: 2;
        }
      }

      &-big {
        max-width: 560px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;

        .product__item {
          padding: 5px 20px;
          margin-bottom: 20px;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        .product__item-category {
          flex-direction: row;
          align-items: center;
          flex: 1;
        }

        .product__item-category__img {
          justify-content: flex-end;

          &::before {
            left: auto;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &__img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    min-height: 215px;

    

    img {
      // height: 100%;
      max-width: 100%;
    }

    &.promo img {
      height: auto;
    }
    &.promo span.percent {
      top: -10px;
      right: -10px;
    }
.info-promotion{
  top:10px;
}
.percent-promotion{
  top:10px;
}
    &.promo span.info {
      top: -10px;
      left: -10px;
    }

    span {
      position: absolute;
      top: 10px;

      &.hit {
        padding: 4px 8px;
        box-sizing: border-box;
        background-color: $blue;
        color: $white;
        font-weight: 800;
        font-size: 10px;
        line-height: 160%;
        text-transform: uppercase;
        left: 10px;
        border-radius: 4px;
      }

      &.info {
        padding: 4px 8px;
        box-sizing: border-box;
        background-color: $yellow;
        font-weight: 800;
        font-size: 10px;
        line-height: 160%;
        text-transform: uppercase;
        left: 10px;
        border-radius: 4px;

        // &.hr {
        //   left: 0;
        //   top: 0;
        // }
      }

      &.percent {
        padding: 4px 8px;
        box-sizing: border-box;
        background-color: $red;
        color: $white;
        right: 10px;
        font-weight: 800;
        font-size: 13px;
        line-height: 160%;
        border-radius: 4px;
        position: absolute;
        
      }
    }
  }

  &__inner {
    padding: 3px 20px 18px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    justify-content: space-between;

    // @media screen and (max-width: 450px) {
    //   padding: 10px;
    // }

    span {
      font-weight: bold;

      &.product-code {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: $blue;
        // @media screen and(max-width: 426px) { 
        //   font-size: 12px;
        // }
      }

      &.product-min-part {
        cursor: default;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;

        @media screen and (max-width: 426px) { 
          display: none;
        }
      }
    }

    .stars {
      font-size: 12px;
      line-height: 100%;
      font-family: $font-meri;
      color: #777777;
      display: flex;
      align-items: center;
      gap: 7px;
      transition: 0.3s ease;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      @media screen and (max-width: 390px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .line {
      border-top: 1px solid #EFEFEF;
      margin: 12px 0;
    }

    .description {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #414D5F;
      flex: 1;
      margin-bottom: 8px;
      font-family: $font-meri;
      cursor: pointer;
      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &.long {
        -webkit-line-clamp: 4;
      }

      // @media screen and(max-width: 426px) {
      //   font-size: 12px;
      // }
    }

    .btn {
      width: 38px;
      height: 38px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    cursor: pointer;

    // @media screen and(max-width: 426px) { 
    //   font-size: 12px;
    // }
  }

  &__footer {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price {
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      cursor: default;
    }
  }

  &-category {
    .swiper {
      .product__item {
        max-width: 286px;
        height: auto;
        margin-bottom: 0;
        @media screen and (max-width: 550px) {
          max-width: calc(100vw - 20px) !important;
        }
      }

      .product__item-category-big {
        max-width: 596px;
        min-width: 596px;
        height: auto;

        .product__item-category {
          max-width: 100%;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 20px;
          &:nth-last-child(1){
            margin-bottom: 0;
          }
        }

      }
    }

    &_1 {
      .swiper .product__item-category-big {
        min-width: 348px;
        max-width: 348px;
      }
    }
  }

  &__inline {
    padding: 10px;
    box-sizing: border-box;
    background: $white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    position: relative;
    margin-bottom: 15px;
    min-height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.empty {
      opacity: 0.5;
      pointer-events: none;
    }
    &-inner {
      display: flex;
      @media screen and (max-width: 1500px) {
        flex-wrap: wrap;
      }
    }
    &-text {
      text-align: start;
    }
    &-footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 8px 9px 10px;

      .price {
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
      }

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background: #EFEFEF;
        top: 7px;
        left: 0;
      }

      .btn {
        width: 38px;
        height: 38px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-img {
      max-width: 80px;
      min-width: 60px;
      margin: 15px 20px 15px 0;
      max-height: 50px;
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }

      img {
        // width: 100%;
        height: 100%;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      margin-bottom: 5px;
    }

    &-code {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: $blue;
      margin-bottom: 5px;

    }

    &-desc {
      font-family: $font-meri;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: #414D5F;
      margin-bottom: 5px;
    }

    &-discount {
      font-weight: 800;
      font-size: 10px;
      line-height: 160%;
      text-align: center;
      text-transform: uppercase;
      color: $white;
      background: $red;
      border-radius: 4px;
      padding: 4px 10px;
      box-sizing: border-box;
      width: fit-content;
      position: absolute;
      left: 0;
      top: 0;
    }

    &__slider {
      // margin-bottom: 15px;
      height: 100%;
    }

    &__slider-banner {
      padding: 0;

      .product__inline {
        padding: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__discount {
    &-title {
      font-family: $font-meri;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #414D5F;
      margin-bottom: 5px;
    }

    &-list {
      display: flex;
      padding: 0;
      justify-content: space-between;
      max-width: 160px;
      width: 100%;
    }

    &-item {
      font-family: $font-meri;
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
      text-align: center;
      color: #414D5F;
      background: #EBF0F8;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //margin: 0 3px;

      span {
        font-weight: normal;
        font-size: 9px;
      }
    }
  }

  &__count {
    &-list {
      // max-width: 208px;
      width: 100%;
      margin-right: 20px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
      margin-bottom: 12px;
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: #1F1F1F;
    }

    &-control {
      display: flex;
      align-items: center;

      button {
        background: #EBF0F8;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        border: 1px solid #EBF0F8;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          border: 1px solid $blue;
        }
      }

      input {
        width: 60px;
        border: none;
        text-align: center;
        outline: none;
        font-family: $font-meri;
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: #1F1F1F;
      }
    }
  }

  .js-rating {
    // margin-right: 6px;
  }
}

p,
.description {
  font-family: $font-meri;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #414D5F;
}

.slick-dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: -15px;

  li {
    width: 100%;
    margin-right: 5px;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    button {
      width: 100%;
      font-size: 0;
      background: #D5E6F1;
      border-radius: 12px;
      height: 3px;
      border: none;
      outline: none;
      transition: background 0.3s ease;
    }

    &:hover,
    &.slick-active {
      button {
        background-color: $blue;
      }
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  bottom: 0 !important;

  .swiper-pagination-bullet {
    width: 100% !important;
    font-size: 0;
    background: #D5E6F1;
    border-radius: 12px;
    height: 3px;
    border: none;
    outline: none;
    transition: background 0.3s ease;

    &.swiper-pagination-bullet-active {
      background-color: $blue;
    }
  }
}

.products-block {
  min-width: 150px;
  .d-flex {
    justify-content: space-between;
  }
}
.like {
  position: absolute;
  right: 15px;
  top: -10px;
  width: 28px;
  height: 28px;
  background-color: #EBF0F8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease;
  z-index: 7;

  &.table-type {
    position: static;
  }

  &.on-card {
    left: -45px;
    right: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  svg {
    transition: 0.3s ease;
  }

  &.active,
  &:hover {
    background-color: $blue;

    svg {
      fill: $white;
      transform: scale(1.2);
      path {
        fill: $white;
      }
    }

  }
}

.product-listing .product__item {
  // margin: 10px;
}

.product__inline__slider {
  // height: calc(220px * 2);

  .swiper-slide {
    // height: calc((100% - 30px) / 2) !important;
    height: 100%;
  }

}
.product__list.product-listing{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
@media screen and (max-width: 1820px) {
  .product__inline__slider {
    height: calc(240px * 2);

  }
}

@media screen and (max-width: 1440px) {
  .product-listing .product__item {
    max-width: calc((100% / 4) - 20px);
  }
}

@media screen and (max-width: 1380px) {
  .product-listing .product__item {
    max-width: calc((100% / 3) - 20px);
  }
}

@media screen and (max-width: 1440px) {
  .product-listing .product__item.hr {
    max-width: calc((100% / 2) - 20px);
  }
}

// @media screen and (max-width: 1380px) {
//   .product-listing .product__item.hr {
//     max-width: calc((100% / 2) - 20px);
//   }
// }

// @media screen and (max-width: 1130px) {
//   .product-listing .product__item.hr {
//     max-width: calc((100% / 2) - 20px);
//   }
// }

// @media screen and (max-width: 992px) {
//   .product-listing .product__item {
//     max-width: calc((100% / 3) - 20px);
//   }
// }

@media screen and (max-width: 800px) {
  .product-listing .product__item {
    max-width: calc((100% / 2) - 20px);
  }
}

@media screen and (max-width: 390px) {
  .product-listing.wish-list .product__item {
    max-width: 100%;
    margin: 0 0 20px;
  }
}
@media screen and (max-width: 500px) {
  .product-listing .product__item {
    // max-width: calc((100% / 2) - 20px);
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .product__item-info {
    display: none;
  }
  .product__list-wrapper {
    max-width: 100%;
  }
}